<!-- 
改的时候一定要注意
这个页面在产品登录模块里也有用到【components/system/LoginDialog】
 -->
<template>
  <a-form-model
    ref="form"
    :model="formModel"
    :rules="formRules"
    :wrapper-col="{ span: 20 }"
    style="margin-left: 70px"
    @submit="submitClick"
    @submit.native.prevent
  >
    <a-tabs v-model="tabKey" :animated="false">
      <a-tab-pane :key="1" tab="账号密码">
        <a-form-model-item prop="user">
          <a-input
            v-model.trim="formModel.user"
            size="large"
            :maxLength="11"
            placeholder="请输入手机号"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-model-item>
        <a-form-model-item prop="password">
          <a-input-password
            v-model.trim="formModel.password"
            size="large"
            placeholder="请输入密码"
            :maxLength="20"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input-password>
        </a-form-model-item>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="手机验证码">
        <a-form-model-item prop="user">
          <a-input
            v-model.trim="formModel.user"
            size="large"
            placeholder="请输入手机号"
            :maxLength="11"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-model-item>
        <VerificationCode v-model="formModel.code" :validate="validatePhone" />
      </a-tab-pane>
    </a-tabs>
    <a-form-model-item prop="isRead">
      <a-checkbox v-model="formModel.isRead">
        我已阅读并同意
        <router-link to="/docs/user" target="_blank"> 《用户协议》 </router-link
        >和
        <router-link to="/docs/privacy" target="_blank">
          《隐私政策》</router-link
        >
      </a-checkbox>
    </a-form-model-item>
    <a-form-model-item>
      <a-button type="primary" block html-type="submit" size="large">
        授权并登录
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { Base64 } from "js-base64";
import OtherLogin from "@/components/system/OtherLogin";
import SetPassword from "@/components/system/SetPassword";
export default {
  props: {
    referrer: String,
  },
  components: {
    VerificationCode: () => import("@/components/system/VerificationCode"),
  },
  data() {
    return {
      tabKey: 1,
      source: 4,
      formModel: {
        user: "",
        password: "",
        code: "",
        isRead: true,
      },
      formRules: {
        user: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请输入手机号"));
              }
              if (!this.api.validate.isPhoneNumber(value)) {
                return callback(new Error("手机号格式错误"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        password: [
          {
            validator: (rule, value, callback) => {
              if (this.tabKey == 1 && !value) {
                return callback(new Error("请输入密码"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        code: [
          {
            validator: (rule, value, callback) => {
              if (this.tabKey == 2) {
                if (!value) {
                  return callback(new Error("请输入验证码"));
                }
                if (value.length != 6) {
                  return callback(new Error("验证码必须为6位"));
                }
              }
              callback();
            },
            trigger: "submit",
          },
        ],
        isRead: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                return callback(new Error("请仔细认真阅读协议内容并勾选同意"));
              }
              callback();
            },
            trigger: "submit",
          },
        ],
      },
      isSubmit: false,
    };
  },
  created() {},
  methods: {
    validatePhone() {
      return new Promise((resolve, reject) => {
        this.$refs.form.validateField("user", async (errMsg) => {
          if (!errMsg) {
            resolve({ bizType: 1, mobile: this.formModel.user });
          } else {
            reject();
          }
        });
      });
    },
    submitClick() {
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          if (this.isSubmit) return;
          this.isSubmit = true;
          const { user, password, code } = this.formModel;
          var params = {
            passwordOrCode: "",
            userName: user,
            source: this.source,
          };
          var fn = null;
          if (this.tabKey == 1) {
            params.passwordOrCode = Base64.encode(password);
            fn = this.api.service.uac_oapi_sso_mobilePasswordLogin(params, {});
          } else {
            params.passwordOrCode = code;
            fn = this.api.service.uac_oapi_sso_mobileVcLogin(params, {});
          }
          fn.then((res) => {
            this.api.toast(res, 0).then(() => {
              const { data } = res;
              this.api.storage.setCookie("systemName", "UCS", { path: "/" });
              this.api.storage.setCookie("UCS-AUTHEN-TOKEN", data.token, {
                path: "/",
              });
              // 判断是否设置密码
              if (!data.passFalg) {
                this.api
                  .component(SetPassword, {
                    data: {
                      userName: user,
                    },
                  })
                  .then(() => {
                    this.redirect(res.data);
                  })
                  .catch(() => {
                    this.logout();
                  });
              }
              // 判断是否有人登录
              else if (data.otherLogin) {
                this.api
                  .component(OtherLogin)
                  .then(() => {
                    this.redirect(res.data);
                  })
                  .catch(() => {
                    this.logout();
                  });
              } else {
                this.redirect(res.data);
              }
            });
          }).finally(() => {
            this.isSubmit = false;
          });
        }
      });
    },
    logout() {
      // 退出登录
      this.api.service.uac_oapi_sso_removeOtherLogin();
    },
    redirect({ accountType }) {
      let redirectUrl = this.api.query("redirectUrl");
      // 从产品来
      if (this.referrer) {
        redirectUrl = this.referrer;
      }
      // 为了安全，必须同域
      if (redirectUrl) {
        // 如果跳转的域名一样，则执行刷新
        if (redirectUrl === this.$route.fullPath) {
          location.reload();
        } else {
          this.$router.replace(redirectUrl);
        }
      } else {
        this.api.service.fpc_oapi_subsystems_query().then(({ data }) => {
          // 若用户是企业普通员工且有被授权应用，跳转到【工作台】业务
          if (accountType === 0 && data === 1) {
            location.href = "/ucs/home";
          } else {
            this.$router.push("/");
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-tabs-bar {
  width: 341px;
  margin-top: 20px;
  .ant-tabs-tab {
    width: 155px;
    text-align: center;
    font-size: 18px;
  }
}
</style>
